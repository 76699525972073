import React from 'react';
import Bloques from '../components/Bloques/Bloques';
import NombreBio from '../components/NombreBio/NombreBio';
import Curriculum from '../Pdf/CV Estephania Osuna.pdf';
import Prefoto from '../Images/Prefoto.png';

const Home = () => {
    return (
        <div className='bloques-contenedor'>
            <NombreBio />
            <Bloques
                texto='Whatsapp'
                href="https://wa.me/6692225655?text=Hola+Estephania,+me+pongo+en+contacto+contigo+para:"
                target='_blank'
                rel='noopener noreferrer'
            />
            
            <Bloques
                texto='Logros y Reconocimientos'
                to='/logros/y/reconocimientos'
            />
            <Bloques
                texto='Voluntariado'
                to='/voluntariado'
            />
            <Bloques
                texto='Descargar CV'
                href={Curriculum}
                download
                imagen={Prefoto}

            />
        </div>
    );
}

export default Home;
