import React from 'react'
import './NombreBio.css'
import Fany from '../../Images/Fany.jpeg'

const NombreBio = () => {
  return (
    <div className='contenedor-mayor'>
      <div className='seccion-superior'>
      <img src={Fany} alt="" />
      <h3>Estephania Osuna Zamora</h3>
      <p>Pasión por conectar culturas y mercados</p>
    </div>
    </div>
    
  )
}

export default NombreBio