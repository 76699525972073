import React from 'react';
import './Voluntariado.css';
import BotonFijo from '../components/BotonFijo/BotonFijo';

const Voluntariado = () => {
  return (
    <div className='voluntariado-limite'>
      <div className="voluntariado-textos">
        <h2>Voluntariado en Rescate y Apoyo a Animales de la Calle</h2>
        <p>Durante siete años, he dedicado mis esfuerzos al rescate y apoyo de 41 animales de la calle, contribuyendo significativamente a mejorar su calidad de vida. Mi trabajo incluyó la coordinación de atención veterinaria para casos críticos, la promoción de adopciones responsables y la concientización sobre el bienestar animal. También gestioné la provisión de alimentos, medicamentos y apoyo económico, así como la organización de transporte y resguardo en situaciones de emergencia. Para respetar la dignidad de los animales y evitar el impacto emocional, he decidido no incluir imágenes en esta presentación. Aunque estas actividades han sido profundamente gratificantes, he concluido mi labor en este ámbito debido al desgaste emocional que conlleva. Esta experiencia ha fortalecido mis habilidades en gestión, comunicación y empatía.</p>
      </div>

      {/* Botón Fijo */}
      <BotonFijo />
    </div>
  );
};

export default Voluntariado;
