import React from 'react'
import BimboR from '../../Images/BimboReconocimiento.jpeg'
import CrokisUno from '../../Images/CrokisPrimerLugar.jpeg'
import CrokisProduct from '../../Images/CrokisProducto.jpeg'
import Pisi from '../../Images/PisiReconocimiento.jpeg'
import DiplomaUni from '../../Images/DiplomaUni.jpeg'



import './LogrosReconocimientos.css'

const LogrosReconocimientos = () => {
    return (
        <div className='contenedor-imagenes'>
            <img src={DiplomaUni} alt="" />
            <img src={CrokisProduct} alt="" />
            <img src={CrokisUno} alt="" />
            <img src={Pisi} alt="" />
            <img src={BimboR} alt="" />
        </div>
    )
}

export default LogrosReconocimientos