import React from 'react';
import { Link } from 'react-router-dom';
import './Bloques.css';

const Bloques = (props) => {
  const { texto, href, to, target, rel, download, imagen } = props;

  // Determina el tipo de enlace a renderizar
  if (href) {
    return (
      <a href={href} target={target} rel={rel} download={download} style={{ textDecoration: 'none' }}>
        <div className='bloque-texto'>
          {imagen && <img src={imagen} alt="" className='bloque-imagen' />}
          <div>{texto}</div>
        </div>
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to} style={{ textDecoration: 'none' }}>
        <div className='bloque-texto'>
          {imagen && <img src={imagen} alt="" className='bloque-imagen' />}
          <div>{texto}</div>
        </div>
      </Link>
    );
  }

  return (
    <div className='bloque-texto'>
      {imagen && <img src={imagen} alt="" className='bloque-imagen' />}
      <div>{texto}</div>
    </div>
  );
}

export default Bloques;
