import './App.css';
import Home from './Paginas/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Logros from './Paginas/Logros';
import Voluntariado from './Paginas/Voluntariado';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/logros/y/reconocimientos' element={<Logros/>}></Route>
          <Route path='voluntariado' element={<Voluntariado/>}></Route>
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
