import React from 'react';
import { Link } from 'react-router-dom';
import './BotonFijo.css'; // Asegúrate de tener el archivo CSS

const BotonFijo = () => {
  return (
    <Link to="/" className="boton-fijo">
      Ir a Inicio
    </Link>
  );
};

export default BotonFijo;
