import React from 'react'
import './Logros.css'
import LogrosReconocimientos from '../components/LogrosReconocimientos/LogrosReconocimientos'
import BotonFijo from '../components/BotonFijo/BotonFijo';

const Logros = () => {
  return (
    <div className='logros'>
      <BotonFijo />
      <div className="logros-reconocimientos">
        <LogrosReconocimientos />
      </div>


    </div>
  )
}

export default Logros;